<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
// 重置CSS样式
import '@/assets/css/reset.css';


export default {

};
</script>


<style lang="scss">
:root {
  --themeColor--: #333; // 定义主题颜色
}
</style>
