<!-- 首页 -->
<template>
    <div id="home" class="page_min_width">
        <!-- 页眉 -->
        <header>
            <!-- 大图背景 -->
            <div class="home-image">
                <h1>Welcome to CRY's Blog</h1>
                <!-- 名人名言 -->
                <h2>{{ saying }}</h2>
            </div>
        </header>

        <!-- 侧边栏 -->
        <aside>
            <div class="container">
                <div class="menu-wrap">
                    <!-- 导航列表 侧边栏内容 -->
                    <div class="menu">
                        <!-- 需要传入 navItem 一个数组，用于渲染侧边导航项目 -->
                        <AsideMenu :navItem="asideNavItem"></AsideMenu>
                    </div>
                    <!-- 侧边栏关闭按钮 -->
                    <button class="close-button" id="close-button">Close Menu</button>
                    <!-- 侧边栏形态 -->
                    <div class="morph-shape" id="morph-shape"
                        data-morph-open="M-7.312,0H15c0,0,66,113.339,66,399.5C81,664.006,15,800,15,800H-7.312V0z;M-7.312,0H100c0,0,0,113.839,0,400c0,264.506,0,400,0,400H-7.312V0z">
                        <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 100 800"
                            preserveAspectRatio="none">
                            <path d="M-7.312,0H0c0,0,0,113.839,0,400c0,264.506,0,400,0,400h-7.312V0z" />
                        </svg>
                    </div>
                </div>
                <!-- /menu-wrap -->
                <!-- 侧边栏开启按钮 -->
                <button class="menu-button" id="open-button">Open Menu</button>
            </div>
            <!-- /container -->
        </aside>

        <!-- 内容主体 -->
        <main>
            <div class="notFound">
                <img src="@/assets/images/404.jpg" alt="">
            </div>

            <!-- 轮播图 -->
            <div class="carousel">

            </div>
        </main>

        <!-- 页脚 -->
        <footer></footer>

        <!-- 鱼塘特效 -->
        <div class="fish">
            <div id="jsi-flying-fish-container" class="container" style="height:200px;"></div>
        </div>
        <!-- 鱼塘占位 -->
        <div class="fishPerch">
            <a href="https://beian.miit.gov.cn/">粤ICP备2023023815号</a>
        </div>

    </div>
</template>

<script>
// 引入CSS样式
import '@/assets/scss/class.scss';  // 原子类
import '@/assets/css/menu_bubble.css';  // 侧边栏样式

// 引入组件
import AsideMenu from '@/components/aside-menu.vue';

// 引入axios
import axios from 'axios';


export default {
    // 注册组件
    components: {
        AsideMenu,
    },
    // 数据
    data() {
        return {
            saying: '',     // 首页鸡汤名言
            // 侧边导航列表
            asideNavItem: [
                {
                    name: '首页',
                    class: 'icon-shouye',
                },
                {
                    name: '联系',
                    class: 'icon-navigation2',
                },
                {
                    name: '订阅',
                    class: 'icon-shoucang',
                },
                {
                    name: '管理',
                    class: 'icon-shezhi',
                },
                {
                    name: 'Gitee',
                    class: 'icon-gitee-fill-round',
                },
            ],
        };
    },
    // 生命周期钩子created 用于初始化页面
    created() {
        // 载入雪花特效
        let script1 = document.createElement('script');
        script1.type = 'text/javascript';
        script1.src = '/static/js/star.js';
        document.body.appendChild(script1);

        // 载入侧边栏特效
        let script2 = document.createElement('script');
        let script3 = document.createElement('script');
        script2.type = 'text/javascript';
        script3.type = 'text/javascript';
        script2.src = '/static/js/classie.js';
        script3.src = '/static/js/bubble-menu.js';
        document.body.appendChild(script2);
        document.body.appendChild(script3);

        // 鱼塘特效
        let script4 = document.createElement('script');
        script4.type = 'text/javascript';
        script4.src = '/static/js/fish.js';
        document.body.appendChild(script4);
    },
    // 生命周期钩子mounted 用于初始ajax请求
    mounted() {
        // 请求鸡汤名言
        (async () => {
            let data;
            try {
                // get请求鸡汤
                data = await axios.get('https://zj.v.api.aa1.cn/api/wenan-shici/?type=json');
                // console.log(data);
                // 获取鸡汤
                data = data.data.msg;

                // 将鸡汤赋值给saying
                this.saying = data || '自信可改变未来。 ——黄家驹';
            } catch (error) {
                console.log('鸡汤请求错误');
                console.log(error);
                this.saying = '自信可改变未来。 ——黄家驹';
            }


        })();
    },
};
</script>

<style lang="scss" scoped>
#home {

    // 页眉
    header {
        .home-image {
            height: 60vh;
            width: 100%;
            background: url('@/assets/images/海琴.webp') no-repeat center center/cover;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            >h1 {
                font-family: 'Playball', cursive;
                font-size: 48px;
                font-weight: 700;
                margin-bottom: 10px;
                color: #fff;
            }

            >h2 {
                width: 800px;
                font-family: 'Playball', cursive;
                font-size: 20px;
                line-height: 1.5em;
                color: rgba(#fff, 0.8);
                text-align: center;
            }
        }
    }

    // 侧边栏
    aside {

        // 导航列表 侧边栏内容
        .menu {
            height: 100%;
        }

        .morph-shape {
            // 侧边栏颜色
            fill: var(--themeColor--);
        }
    }

    // 页面主体
    main {
        .notFound {
            width: fit-content;
            margin: 0 auto;

            >img {
                width: 1170px;
                height: 800px;
                object-fit: contain;
            }
        }
    }

    // 页脚
    footer {
        height: 100px;
        // background-color: rgba(skyblue, 0.6);
    }

    // 鱼塘
    .fish {
        position: fixed;
        left: 0;
        bottom: 0;
        z-index: -1;
        width: 100%;
    }

    .fishPerch {
        height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;

        >a {
            font-size: 12px;
            cursor: pointer;

            &:hover {
                color: skyblue;
            }
        }
    }
}
</style>