<!-- 侧边栏内容 -->
<template>
    <div id="aside-menu">
        <!-- 侧边栏顶部内容背景 -->
        <div class="head">
            <!-- 头像，粉丝数 -->
            <div class="preson">
                <!-- 头像框 -->
                <div class="person_frame">
                    <!-- 头像 -->
                    <div class="person_head">
                        <img src="@/assets/images/aside_head.png" alt="">
                    </div>
                </div>
                <!-- 用户信息 -->
                <div class="person_info">
                    <p>昵称：CRY</p>
                    <p>博龄：1年03个月</p>
                    <p>粉丝：602</p>
                    <p>关注：33</p>
                </div>
            </div>
            <!-- 加关注 -->
            <div class="addFollow">
                <p>+加关注</p>
            </div>
        </div>

        <!-- 侧边栏内容 -->
        <div class="aside-content">
            <!-- 导航 -->
            <div class="navigator">
                <!-- 导航列表 -->
                <ul>
                    <!-- 列表渲染 侧边导航栏 -->
                <!-- 
                        这里需要父组件提供一个数组 navItem
                        navItem=[
                            {
                                name: '首页',   // 导航名称
                                class: 'icon-shouye',  // 导航图标
                            }
                        ]
                                                                                                                                        -->
                    <template v-for="item in navItem">
                        <li>
                            <i class="iconfont" :class="item.class"></i>
                            <p>{{ item.name }}</p>
                        </li>
                    </template>
                </ul>
            </div>

            <!-- 日历 -->
            <div class="calendar">
                <date-picker v-model='selectedDate' color="red" is-expanded is-dark />
            </div>

            <!-- 搜索与推荐 -->
            <div class="searchAndRecommend">
                <!-- 搜索 -->
                <div class="aside-search">
                    <h2>找找看</h2>
                    <!-- 搜索框 -->
                    <input type="text" />
                </div>
                <!-- 推荐 -->
                <!-- 留坑，需要结合传入数据渲染一级列表和二级列表 -->
                <div class="aside-recommend" v-for="item in 3">
                    <h2>随笔分类&nbsp;<i class="iconfont icon-shangxiajiantou"></i></h2>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    name: 'aside-menu',
    data() {
        return {
            selectedDate: new Date(),  // 日历组件，选中的日期
        };
    },
    // 暴露给外部组件
    props: ['navItem'],
};
</script>

<style lang="scss" scoped>
#aside-menu {
    height: 100%;
    overflow-y: overlay;
    overflow-x: hidden;

    // 滚动条样式
    &::-webkit-scrollbar {
        width: 4px;
        // height: 4px;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        background: transparent;
        border: 1px solid rgba(#fff, 0.4);
    }

    &::-webkit-scrollbar-track {
        border-radius: 0;
        background: transparent;
    }

    // *滚动条样式

    // 侧边栏顶部内容背景
    .head {
        position: relative;
        height: 225px;
        width: 100%;
        background: url("@/assets/images/aside_bgc.jpg") no-repeat center center/cover;

        display: flex;
        justify-content: center;
        align-items: center;

        // 头像，粉丝数
        .preson {
            display: flex;
            flex-direction: column;
            align-items: center;

            // 头像框
            .person_frame {
                height: 80px;
                width: 80px;
                border-radius: 50%;
                background-color: rgba(#fff, 0.5);
                cursor: pointer;
                transition: transform 1s ease-out;

                display: flex;
                justify-content: center;
                align-items: center;

                &:hover {
                    transform: rotate(360deg);
                }

                // 头像
                .person_head {
                    height: 74px;
                    width: 74px;
                    border-radius: 50%;
                    overflow: hidden;

                    >img {
                        width: 100%;
                    }
                }
            }

            // 用户信息
            .person_info {
                margin-top: 10px;

                >p {
                    color: #f5f5f5;
                    line-height: 20px;
                    font-size: 14px;
                    font-family: Merriweather, "Open Sans", "Microsoft Jhenghei", "Microsoft Yahei", sans-serif;
                }
            }
        }

        // 加关注
        .addFollow {
            position: absolute;
            bottom: 20px;
            right: 20px;
            cursor: pointer;

            &:hover {
                >p {
                    color: rgba(#fff, 0.7);
                }
            }

            >p {
                color: #f5f5f5;
                font-size: 16px;
                font-weight: 700;
                font-family: Merriweather, "Open Sans", "Microsoft Jhenghei", "Microsoft Yahei", sans-serif;
            }
        }
    }

    // 侧边栏内容
    .aside-content {
        padding-right: 14px;
        box-sizing: border-box;

        // 导航
        .navigator {
            padding: 0px 14px;
            box-sizing: border-box;

            >ul {
                margin: 6px 0px 12px;

                >li {
                    position: relative;
                    display: flex;
                    align-items: center;
                    padding: 6px 0px;
                    line-height: 1.4;
                    counter-increment: item;
                    cursor: pointer;

                    &:hover {
                        >p {
                            color: #fff;
                        }
                    }

                    // 目录罗马字
                    &::after {
                        position: absolute;
                        content: counter(item, lower-roman);
                        font-size: 16px;
                        color: #666;
                        right: -43px;
                        width: 22px;
                        height: 20px;
                        bottom: 8px;
                        z-index: 1;
                        background: var(--themeColor--);
                        padding: 0 40px 0 5px;

                    }

                    // 导航图标
                    >i {
                        color: #888;
                        font-size: 16px;
                        margin-right: 10px;
                    }

                    // 导航文字
                    >p {
                        position: relative;
                        color: #b8b8b8;
                        font-size: 16px;
                        font-family: Open Sans, Microsoft Jhenghei, Microsoft Yahei, sans-serif;
                        // width: 200px;
                        // overflow: hidden;

                        // 省略号
                        &::before {
                            position: absolute;
                            right: 0px;
                            bottom: 0px;
                            transform: translateX(100%);
                            display: inline-block;
                            content: ' ............................................................................';
                            color: hsla(0, 0%, 100%, .2);
                            width: 160px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            padding-left: 5px;
                            vertical-align: bottom;
                        }
                    }
                }
            }
        }

        // 日历
        // .calendar {}

        // 搜索与推荐
        .searchAndRecommend {

            // 搜索与推荐
            .aside-search,
            .aside-recommend {
                position: relative;
                width: 100%;
                border-top: 1px solid #666;
                margin: 24px 0px;
                // padding-top: 24px;

                >h2 {
                    position: absolute;
                    top: 0px;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    width: 80px;
                    line-height: 20px;
                    color: #b8b8b8;
                    font-size: 14px;
                    text-align: center;
                    background-color: var(--themeColor--);
                    cursor: pointer;

                    &:hover {
                        >i {
                            color: #b8b8b8;
                        }
                    }

                    >i {
                        font-size: 12px;
                        color: rgba(#b8b8b8, 0.6);
                    }
                }
            }

            // 搜索
            .aside-search {

                // 搜索框
                >input {
                    display: block;
                    margin: 0 auto;
                    margin-top: 24px;
                    padding-left: 5px;
                    width: 90%;
                    height: 30px;
                    border: 1px solid rgba(#666, 0.6);
                    border-radius: 3px;
                    outline: none;
                    background-color: var(--themeColor--);
                    color: #ccc;
                    transition: border-color 0.3s ease-in-out;

                    &:focus {
                        border-color: #666;
                    }
                }
            }
        }
    }

}
</style>