import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

// 引入饿了么组件相关文件
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

// 向 Vue 注册饿了么插件
Vue.use(ElementUI);

// 引入v-calendar 日历组件
import Calendar from 'v-calendar/lib/components/calendar.umd';
import DatePicker from 'v-calendar/lib/components/date-picker.umd';

// 注册v-calendar 日历组件
Vue.component('calendar', Calendar);
Vue.component('date-picker', DatePicker);


Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
